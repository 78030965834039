// WarehouseDashboard.jsx
import React from 'react';
import './Dashboard.css';
import reportIcon from './assets/punchview.png';
import newreportIcon from './assets/plus-button.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const DespatchDashboard = () => {
  const navigate = useNavigate();


  const handleNewReportClick = () => {
    navigate('/DespatchReport');
  };

  const handleViewReportClick = () => {
    navigate('/DespatchView');
  };

  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Despatch Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleNewReportClick}>
                <img src={newreportIcon} alt="New Reports" />
                <p>Create New Despatch Report</p>
              </div>
        <div className="widget" onClick={handleViewReportClick}>
                <img src={reportIcon} alt="View Reports" />
                <p>View Despatch Reports</p>
              </div>
      </div>
    </div>
  </>
  );
};

export default DespatchDashboard;
