// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; // Ensure this is correctly imported
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import FavouritesModal from './FavouritesModal';
import helpdeskIcon from './assets/helpdesk-icon.svg';
import factoryIcon from './assets/factory.png';
import warehouseIcon from './assets/warehouse-icon.svg';
import driverIcon from './assets/van.png';
import sketchIcon from './assets/sketch-icon.svg';
import salesIcon from './assets/sales.png';
import adminIcon from './assets/admin-icon.png'
import noticeIcon from './assets/notice-board.png';
import returnIcon from './assets/product-return.png';

const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to the login route
    } catch (error) {
      console.error('Logout Failed:', error);
      // Handle logout errors
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);


  const handleWarehouseClick = () => {
    navigate('/WarehouseDashboard');
  };

  const handleDespatchClick = () => {
    navigate('/DespatchDashboard');
  };

  const handleFactoryClick = () => {
    navigate('/FactoryDashboard');
  };

  const handleDriverClick = () => {
    navigate('/DeliveryUpload');
  };

  const handleHelpDeskClick = () => {
    navigate('/HelpDeskDashboard');
  };

  const handleSketchClick = () => {
    navigate('/SketchDashboard');
  };

  const handleReturnClick = () => {
    navigate('/ReturnsDashboard');
  }

  const handleSalesClick = () => {
    navigate('/SalesDashboard');
  };

  const handleNoticeClick = () => {
    navigate('/NoticeDashboard');
  };

  const handleSignInClick = () => {
    navigate('/SignInOutSystem');
  };

  const handleSignInAdminClick = () => {
    navigate('/SignInAdmin');
  };

  const handleAdminClick = () => {
    navigate('/AdminDashboard');
  }

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const db = getFirestore();
        const userDocRef = doc(db, "Users", userEmail);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserData(userData); // This now includes the role
        } else {
          console.log("No user data found!");
        }
      }
    };

   
  
    fetchData();
  }, [auth]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="header-title">
          <h1>{userData && userData.name ? `${userData.name}'s Dashboard` : 'Dashboard'}</h1>
        </div>
        <div className="header-actions">
        <button onClick={toggleModal} className="logout-button">Favourites</button>
          <button onClick={handleLogout} className="logout-button">Log Out</button>
          {/* Favourites Modal */}
        </div>
      
      
        <FavouritesModal 
        isOpen={isModalOpen} 
        onClose={toggleModal} 
        favourites={userData && userData.favourites ? userData.favourites : []}
      />

      <div className="widget-container">
        {userData && (
          <>
            {userData.helpdesk && (
              <div className="widget" onClick={handleHelpDeskClick}>
                <img src={helpdeskIcon} alt="Help Desk" />
                <p>Help Desk</p>
                {/* Additional content for Helpdesk */}
              </div>
            )}
            {userData.warehouse && (
              <div className="widget" onClick={handleWarehouseClick}>
                <img src={warehouseIcon} alt="Warehouse" />
                <p>Warehouse</p>
                {/* Additional content for Warehouse */}
              </div>
            )}
            {userData.despatch && (
              <div className="widget" onClick={handleDespatchClick}>
                <img src={driverIcon} alt="despatch" />
                <p>Despatch</p>
                {/* Additional content for Warehouse */}
              </div>
            )}
            {userData.driver && (
              <div className="widget" onClick={handleDriverClick}>
                <img src={driverIcon} alt="Driver" />
                <p>Driver</p>
                {/* Additional content for Warehouse */}
              </div>
            )}
            {userData.factory && (
              <div className="widget" onClick={handleFactoryClick}>
                <img src={factoryIcon} alt="Warehouse" />
                <p>Factory</p>
                {/* Additional content for Warehouse */}
              </div>
            )}
            {userData.sketch && (
              <div className="widget" onClick={handleSketchClick}>
                <img src={sketchIcon} alt="Sketch" />
                <p>Sketches</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.returns && (
              <div className="widget" onClick={handleReturnClick}>
                <img src={returnIcon} alt="Sketch" />
                <p>Returns</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.sales && (
              <div className="widget" onClick={handleSalesClick}>
                <img src={salesIcon} alt="Sales" />
                <p>Sales</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.notice && (
              <div className="widget" onClick={handleNoticeClick}>
                <img src={noticeIcon} alt="Sales" />
                <p>Notice Board</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.tablet && (
              <div className="widget" onClick={handleSignInClick}>
                <img src={noticeIcon} alt="Sales" />
                <p>Sign In/Out</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.tabletadmin && (
              <div className="widget" onClick={handleSignInAdminClick}>
                <img src={adminIcon} alt="Sales" />
                <p>Sign In Admin</p>
                {/* Additional content for Sketches */}
              </div>
            )}
            {userData.admin && (
              <div className="widget" onClick={handleAdminClick}>
                <img src={adminIcon} alt="Sales" />
                <p>Admin Panel</p>
                {/* Additional content for Sketches */}
              </div>
              
            )}
            </>
        )}
    </div>
  </div>
</div>
  )};

export default Dashboard;
